<template>
    <div class="centered">
        <loading />
    </div>
</template>

<script>
import liff from '@line/liff';
import Store from '@/store/index';
import Loading from '@/components/Loading.vue';

export default {
    components: {
        Loading,
    },
    computed: {
        to() {
            return this.$route.params;
        }
    },
    mounted() {
        try {
            Store.commit('config/init', this.$route.params.disease);
            liff.ready.then(async () => {
                if (!liff.isLoggedIn()) {
                    liff.login({
                        redirectUri: location.href
                    });
                } else {
                    try {
                        await Store.dispatch('auth/lineUserLogin', {
                            idToken: liff.getIDToken(),
                            accessToken: liff.getAccessToken()
                        });
                        this.$router.replace({ name: this.to.name, params: this.to.params });
                    } catch (e) {
                        this.$router.replace({ name: 'Line_NON_HCP', params: this.to.params });
                    }
                    this.$root.$emit('loadConsent');
                }
            });
        } catch (e) {
            this.$router.replace({ name: 'Line_NON_HCP', params: this.to.params });
        }
    }
};
</script>

<style lang="scss" scoped>
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
</style>
